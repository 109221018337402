// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
// _mock
import { _socials } from "src/_mock";
// components
import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
// locales
import { useLocales } from "src/locales";

export default function Footer() {
    const pathname = usePathname();
    const { t } = useLocales();

    const LINKS = [
        {
            headline: t("TRAVELANDZ"),
            children: [
                { name: t("Home"), href: "/" },
                { name: t("About us"), href: paths.about },
                { name: t("Contact us"), href: paths.contact },
                { name: t("Pricing"), href: paths.pricing },
                { name: t("FAQs"), href: paths.faqsaitravelagent },
                ///ai-travel-agents
                { name: t("AI Travel Agents"), href: paths.post.root },
                {
                    name: t("Corporate Travel"),
                    href: "https://travelandz.business",
                },
                {
                    name: t("AI For Travel Agencies"),
                    href: paths.home2,
                },
                {
                    name: t("Login AI For Travel Agencies"),
                    href: paths.authDemo.modern.login,
                },
            ],
        },
        {
            headline: t("Legal"),
            children: [
                { name: t("Terms and Condition"), href: "/terms" },
                { name: t("Privacy Policy"), href: "/privacy" },
            ],
        },
        {
            headline: t("Contact"),
            children: [
                { name: "hello@travelandz.com", href: paths.contact },
                { name: t("AI Travel Planner Team"), href: paths.about },
            ],
        },
    ];

    const isHome = pathname === "/";

    const simpleFooter = (
        <Box
            component="footer"
            sx={{
                py: 5,
                textAlign: "center",
                position: "relative",
                bgcolor: "background.default",
            }}
        >
            <Container>
                <Logo
                    src="/assets/logotz.png"
                    sx={{ mb: 1, mx: "auto", width: "50%" }}
                />

                <Typography variant="caption" component="div">
                    {t("© All rights reserved")}
                    <br /> {t("made by Travelandz Team")}
                </Typography>
            </Container>
        </Box>
    );

    const mainFooter = (
        <Box
            component="footer"
            sx={{
                position: "relative",
                bgcolor: "background.default",
            }}
        >
            <Divider />

            <Container
                sx={{
                    pt: 10,
                    pb: 5,
                    textAlign: { xs: "center", md: "unset" },
                }}
            >
                <Logo
                    src="/assets/logotz.png"
                    sx={{ mb: 5, mx: "auto", width: "14%" }}
                />

                <Grid
                    container
                    justifyContent={{
                        xs: "center",
                        md: "space-between",
                    }}
                >
                    <Grid xs={8} md={3}>
                        <Typography
                            variant="overline"
                            sx={{ color: "text.secondary" }}
                        >
                            {t(
                                "Join Travelandz and Impact Travel with Sustainable, AI-Powered Solutions",
                            )}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                maxWidth: 270,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        >
                            {t(
                                "At Travelandz, we're transforming the travel industry through cutting-edge AI software designed to promote sustainable travel. Our AI-driven solutions empower travelers to make eco-friendly choices, minimize their carbon footprint, and support local communities while exploring the world. Together, we can explore the planet more mindfully, ensuring a positive impact on both nature and society for generations to come.",
                            )}
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent={{ xs: "center", md: "flex-start" }}
                            sx={{
                                mt: 3,
                                mb: { xs: 5, md: 0 },
                            }}
                        >
                            {_socials.map((social) => (
                                <a
                                    key={social.name}
                                    href={social.path}
                                    target={social.path}
                                    rel="noopener noreferrer"
                                >
                                    <IconButton
                                        key={social.name}
                                        sx={{
                                            "&:hover": {
                                                bgcolor: alpha(
                                                    social.color,
                                                    0.08,
                                                ),
                                            },
                                        }}
                                    >
                                        <Iconify
                                            color={social.color}
                                            icon={social.icon}
                                        />
                                    </IconButton>
                                </a>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid xs={12} md={6}>
                        <Stack
                            spacing={5}
                            direction={{ xs: "column", md: "row" }}
                        >
                            {LINKS.map((list) => (
                                <Stack
                                    key={list.headline}
                                    spacing={2}
                                    alignItems={{
                                        xs: "center",
                                        md: "flex-start",
                                    }}
                                    sx={{ width: 1 }}
                                >
                                    <Typography
                                        component="div"
                                        variant="overline"
                                    >
                                        {list.headline}
                                    </Typography>

                                    {list.children.map((link) => (
                                        <Link
                                            key={link.name}
                                            component={RouterLink}
                                            href={link.href}
                                            color="inherit"
                                            variant="body2"
                                        >
                                            {link.name}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography variant="body2" sx={{ mt: 10 }}>
                    {t("© 2024 All rights reserved by Travelandz")}
                </Typography>
            </Container>
        </Box>
    );

    return mainFooter;
}
