import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// layouts
import CompactLayout from "src/layouts/compact";
import MainLayout from "src/layouts/main";
import SimpleLayout from "src/layouts/simple";
// components
import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("src/pages/home"));

const HomePage2 = lazy(() => import("src/pages/home2"));
const Page500 = lazy(() => import("src/pages/500"));
const Page403 = lazy(() => import("src/pages/403"));
const Page404 = lazy(() => import("src/pages/404"));
const FaqsPage = lazy(() => import("src/pages/faqs"));
const FaqsAiPage = lazy(() => import("src/pages/faqs-ai-travel-agent"));
const AboutPage = lazy(() => import("src/pages/about-us"));
const PrivacyPage = lazy(() => import("src/pages/privacy"));
const TermsPage = lazy(() => import("src/pages/terms"));
const ContactPage = lazy(() => import("src/pages/contact-us"));
const SaveTimePage = lazy(() => import("src/pages/save-time"));
const PricingPage = lazy(() => import("src/pages/pricing"));
const TimelinePage = lazy(() => import("src/pages/timeline"));
const PaymentPage = lazy(() => import("src/pages/payment"));
const ComingSoonPage = lazy(() => import("src/pages/coming-soon"));
const MaintenancePage = lazy(() => import("src/pages/maintenance"));
const AgentView = lazy(() => import("src/pages/agent-view"));
const AgentViewBook = lazy(() => import("src/pages/agent-view-book"));
const ClientView = lazy(() => import("src/pages/client-view"));
const ClientViewHotelProposal = lazy(() =>
    import("src/pages/client-view-hotel-proposal"),
);
const ClientViewFlightsProposal = lazy(() =>
    import("src/pages/client-view-flights-proposal"),
);

const ClientViewManualActivitiesProposal = lazy(() =>
    import("src/pages/client-view-manual-activities-proposal"),
);

const ClientBookView = lazy(() => import("src/pages/client-book-view"));
const ClientHome = lazy(() => import("src/pages/client-home"));
const ClientPlan = lazy(() => import("src/sections/client-view/view/index.js"));
const AgencyPaymentPage = lazy(() => import("src/pages/agency-payment"));
const AgencyBookingPage = lazy(() => import("src/pages/agency-booking"));
const ProviderView = lazy(() => import("src/pages/provider-view"));

// PRODUCT
const ProductListPage = lazy(() => import("src/pages/product/list"));
const ProductDetailsPage = lazy(() => import("src/pages/product/details"));
const ProductCheckoutPage = lazy(() => import("src/pages/product/checkout"));
// BLOG
const PostListPage = lazy(() => import("src/pages/post/list"));
const PostDetailsPage = lazy(() => import("src/pages/post/details"));

//CRUISES
const ClientViewCruisesProposalPage = lazy(() =>
    import("src/pages/client-view-cruises-proposal"),
);

// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        element: (
            <MainLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </MainLayout>
        ),
        children: [
            { path: "about-us", element: <AboutPage /> },
            { path: "privacy", element: <PrivacyPage /> },
            { path: "terms", element: <TermsPage /> },
            { path: "home", element: <HomePage /> },
            { path: "home2", element: <HomePage2 /> },
            { path: "agent-view", element: <AgentView /> },
            { path: "client-view", element: <ClientView /> },
            {
                path: "provider-view/:projectId/:companyId/:companyName/:title",
                element: <ProviderView />,
            },

            { path: "agent-client-view", element: <ClientView /> },
            { path: "client-book-view", element: <ClientBookView /> },
            { path: "ai-travel-planner", element: <ClientHome /> },
            { path: "ai-travel-planner/itinerary", element: <ClientPlan /> },
            { path: "contact-us", element: <ContactPage /> },
            { path: "ahorra-tiempo", element: <SaveTimePage /> },
            { path: "faqs", element: <FaqsPage /> },
            { path: "faqs-ai-travel-agent", element: <FaqsAiPage /> },
            {
                path: "product",
                children: [
                    { element: <ProductListPage />, index: true },
                    { path: "list", element: <ProductListPage /> },
                    { path: ":id", element: <ProductDetailsPage /> },
                    { path: "checkout", element: <ProductCheckoutPage /> },
                ],
            },
            {
                path: "ai-travel-agents",
                children: [
                    { element: <PostListPage />, index: true },
                    { path: "list", element: <PostListPage /> },
                    { path: ":title", element: <PostDetailsPage /> },
                ],
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "client-view-book/:id", // Add ":id" here to specify that it's an optional parameter
                element: <ClientBookView />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "agency-payment/:gdsprovider/:id",
                element: <AgencyPaymentPage />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "agency-booking/:gdsprovider/:id",
                element: <AgencyBookingPage />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            { path: "client-view/:id/:companyId", element: <ClientView /> },
            {
                path: "client-view-hotel-proposal/:projectId",
                element: <ClientViewHotelProposal />,
            },
            {
                path: "client-view-flights-proposal/:projectId",
                element: <ClientViewFlightsProposal />,
            },
            {
                path: "client-view-cruises-proposal/:projectId",
                element: <ClientViewCruisesProposalPage />,
            },
            {
                path: "client-view-activities-proposal/:projectId",
                element: <ClientViewManualActivitiesProposal />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "provider-view/:projectId/:companyId/:companyName/:title",
                element: <ProviderView />,
            },
        ],
    },

    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "agent-view-book/:id", // Add ":id" here to specify that it's an optional parameter
                element: <AgentViewBook />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [{ path: "agent-view/:id", element: <AgentView /> }],
    },
    {
        element: (
            <SimpleLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </SimpleLayout>
        ),
        children: [
            { path: "pricing", element: <PricingPage /> },
            { path: "timeline/:id", element: <TimelinePage /> },
            { path: "payment/:id", element: <PaymentPage /> },
        ],
    },
    {
        element: (
            <CompactLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </CompactLayout>
        ),
        children: [
            { path: "coming-soon", element: <ComingSoonPage /> },
            { path: "maintenance", element: <MaintenancePage /> },
            { path: "500", element: <Page500 /> },
            { path: "404", element: <Page404 /> },
            { path: "403", element: <Page403 /> },
        ],
    },
];
