import { _id, _postTitles } from "src/_mock/assets";

import { paramCase } from "src/utils/change-case";

const MOCK_ID = _id[1];
const MOCK_TITLE = _postTitles[2];

const ROOTS = {
    AUTH: "/auth",
    AUTH_DEMO: "/travel-agencies",
    DASHBOARD: "/dashboard",
};

export const paths = {
    comingSoon: "/coming-soon",
    maintenance: "/maintenance",
    agencyPayment: (id, gdsprovider) => `/agency-payment/${gdsprovider}/${id}`,
    agencyBooking: (id, gdsprovider) => `/agency-booking/${gdsprovider}/${id}`,

    agentView: (id) => `/agent-view/${id}`,
    agentViewBook: (id) => `/agent-view-book/${id}`,
    agentViewsClient: (id, companyId) =>
        `/client-view/${id}/${companyId}?type=agent`,
    clientView: (id, companyId) =>
        `/client-view/${id}/${companyId}?type=customer`,
    clientViewBook: (id) => `/client-view-book/${id}`,
    clientViewHotelProposal: (id, agent) =>
        `/client-view-hotel-proposal/${id}?type=${
            agent ? "agent" : "customer"
        }`,
    clientViewFlightsProposal: (id, agent) =>
        `/client-view-flights-proposal/${id}?type=${
            agent ? "agent" : "customer"
        }`,
    clientViewManualActivityProposal: (id, agent) =>
        `/client-view-activities-proposal/${id}?type=${
            agent ? "agent" : "customer"
        }`,
    providerView: (projectId, companyId, companyName, title) =>
        `/provider-view/${projectId}/${companyId}/${companyName}/${title}`,
    clientHome: "/ai-travel-planner",
    clientPlan: "/ai-travel-planner/itinerary",
    pricing: "/pricing",
    timeline: (id) => `/timeline/${id}`,
    payment: (id, queryParams) =>
        `/payment/${id}${
            queryParams && queryParams?.length > 0 ? `?${queryParams}` : ""
        }`,
    about: "/about-us",
    privacy: "/privacy",
    terms: "/terms",
    contact: "/contact-us",
    faqs: "/faqs",
    faqsaitravelagent: "/faqs-ai-travel-agent",
    home2: "/home2",
    page403: "/403",
    page404: "/404",
    page500: "/500",
    docs: "https://docs.minimals.cc",
    changelog: "https://docs.minimals.cc/changelog",
    zoneUI: "https://mui.com/store/items/zone-landing-page/",
    minimalUI: "https://mui.com/store/items/minimal-dashboard/",
    freeUI: "https://mui.com/store/items/minimal-dashboard-free/",
    figma: "https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1",
    product: {
        root: `/product`,
        checkout: `/product/checkout/`,
        details: (id) => `/product/${id}`,
        demo: {
            details: `/product/${MOCK_ID}`,
        },
    },
    post: {
        root: `/ai-travel-agents`,
        details: (title) => `/ai-travel-agents/${paramCase(title)}`,
        demo: {
            details: `/ai-travel-agents/${paramCase(MOCK_TITLE)}`,
        },
    },
    // AUTH
    auth: {
        jwt: {
            login: `${ROOTS.AUTH_DEMO}/modern/login`,
            register: `${ROOTS.AUTH}/modern/register`,
        },
    },
    authDemo: {
        classic: {
            login: `${ROOTS.AUTH_DEMO}/classic/login`,
            register: `${ROOTS.AUTH_DEMO}/classic/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
        },
        modern: {
            login: `${ROOTS.AUTH_DEMO}/modern/login`,
            register: `${ROOTS.AUTH_DEMO}/modern/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
        },
    },
    // DASHBOARD
    dashboard: {
        root: `${ROOTS.DASHBOARD}/home`,
        home: {
            root: `${ROOTS.DASHBOARD}/home`,
        },

        salesOverview: `${ROOTS.DASHBOARD}/salesOverview`,
        financials: `${ROOTS.DASHBOARD}/financials`,
        performanceMetrics: `${ROOTS.DASHBOARD}/performanceMetrics`,
        mail: {
            root: `${ROOTS.DASHBOARD}/mail`,
            sent: (emailId) =>
                `${ROOTS.DASHBOARD}/mail?id=${emailId}&label=Sent`,
        },
        chat: `${ROOTS.DASHBOARD}/chat`,
        blank: `${ROOTS.DASHBOARD}/blank`,
        kanban: `${ROOTS.DASHBOARD}/kanban`,
        calendar: `${ROOTS.DASHBOARD}/calendar`,
        fileManager: `${ROOTS.DASHBOARD}/file-manager`,
        permission: `${ROOTS.DASHBOARD}/permission`,
        general: {
            app: `${ROOTS.DASHBOARD}/app`,
            ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
            analytics: `${ROOTS.DASHBOARD}/analytics`,
            banking: `${ROOTS.DASHBOARD}/banking`,
            booking: `${ROOTS.DASHBOARD}/bookingDashboard`,
            file: `${ROOTS.DASHBOARD}/file`,
        },
        admin: {
            bookings: `${ROOTS.DASHBOARD}/admin/bookings`,
            details: (id) => `${ROOTS.DASHBOARD}/admin/${id}`,
            agencies: `${ROOTS.DASHBOARD}/admin/agencies`,
            tzclient: (id) => `${ROOTS.DASHBOARD}/admin/tzclient/${id}`,
            salesreport: `${ROOTS.DASHBOARD}/admin/salesreport`,
            clientsreport: `${ROOTS.DASHBOARD}/admin/clientsreport`,
            newAgency: `${ROOTS.DASHBOARD}/admin/new-agency`,
            wallet: `${ROOTS.DASHBOARD}/admin/wallet`,
        },
        aipilot: {
            acquisition: `${ROOTS.DASHBOARD}/aipilot/acquisition`,
            experience: `${ROOTS.DASHBOARD}/aipilot/experience`,
            conversion: `${ROOTS.DASHBOARD}/aipilot/conversion`,
            engagement: `${ROOTS.DASHBOARD}/aipilot/engagement`,
        },
        user: {
            root: `${ROOTS.DASHBOARD}/user`,
            new: `${ROOTS.DASHBOARD}/user/new`,
            list: `${ROOTS.DASHBOARD}/user/list`,
            cards: `${ROOTS.DASHBOARD}/user/cards`,
            profile: `${ROOTS.DASHBOARD}/user/profile`,
            account: `${ROOTS.DASHBOARD}/user/account`,
            management: `${ROOTS.DASHBOARD}/user/management`,
            tutorials: `${ROOTS.DASHBOARD}/user/tutorials`,
            onboarding: `${ROOTS.DASHBOARD}/user/onboarding`,
            support: `${ROOTS.DASHBOARD}/user/support`,
            faqs: `${ROOTS.DASHBOARD}/user/faqs`,
            edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
            },
        },
        agency: {
            preferences: `${ROOTS.DASHBOARD}/agency/preferences`,
            membership: `${ROOTS.DASHBOARD}/agency/membership`,
        },
        customer: {
            root: `${ROOTS.DASHBOARD}/customer`,
            new: `${ROOTS.DASHBOARD}/customer/new`,
            leadList: `${ROOTS.DASHBOARD}/customer/leadList`,
            individualList: `${ROOTS.DASHBOARD}/customer/individualList`,
            businessList: `${ROOTS.DASHBOARD}/customer/businessList`,
            cards: `${ROOTS.DASHBOARD}/customer/cards`,
            profile: (customerId) =>
                `${ROOTS.DASHBOARD}/customer/profile/${customerId}`,
            account: `${ROOTS.DASHBOARD}/customer/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/customer/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/customer/${MOCK_ID}/edit`,
            },
        },
        sales: {
            root: `${ROOTS.DASHBOARD}/sales`,
            new: `${ROOTS.DASHBOARD}/sales/new`,
            salesPipeline: `${ROOTS.DASHBOARD}/sales/salesPipeline`,
            leadsConverting: `${ROOTS.DASHBOARD}/sales/leadsConverting`,
            cards: `${ROOTS.DASHBOARD}/sales/cards`,
            profile: `${ROOTS.DASHBOARD}/sales/profile`,
            account: `${ROOTS.DASHBOARD}/sales/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/sales/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/sales/${MOCK_ID}/edit`,
            },
        },

        providers: {
            root: `${ROOTS.DASHBOARD}/provider`,
            new: `${ROOTS.DASHBOARD}/provider/new`,
            list: `${ROOTS.DASHBOARD}/provider/list`,
            cards: `${ROOTS.DASHBOARD}/provider/cards`,
            profile: (providerId) =>
                `${ROOTS.DASHBOARD}/provider/profile/${providerId}`,
            account: `${ROOTS.DASHBOARD}/provider/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/provider/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/provider/${MOCK_ID}/edit`,
            },
        },

        leads: {
            root: `${ROOTS.DASHBOARD}/leads`,
            new: `${ROOTS.DASHBOARD}/leads/new`,
            list: `${ROOTS.DASHBOARD}/leads/list`,
            cards: `${ROOTS.DASHBOARD}/leads/cards`,
            profile: `${ROOTS.DASHBOARD}/leads/profile`,
            account: `${ROOTS.DASHBOARD}/leads/account`,
            campaign: `${ROOTS.DASHBOARD}/leads/campaign`,
            edit: (id) => `${ROOTS.DASHBOARD}/leads/${id}/edit`,
        },

        groupleads: {
            root: `${ROOTS.DASHBOARD}/groupleads`,
            new: `${ROOTS.DASHBOARD}/groupleads/new`,
            list: `${ROOTS.DASHBOARD}/groupleads/list`,
            cards: `${ROOTS.DASHBOARD}/groupleads/cards`,
            profile: `${ROOTS.DASHBOARD}/groupleads/profile`,
            account: `${ROOTS.DASHBOARD}/groupleads/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/groupleads/${id}/edit`,
        },

        campaigns: {
            root: `${ROOTS.DASHBOARD}/campaigns`,
            new: `${ROOTS.DASHBOARD}/campaigns/new`,
            list: `${ROOTS.DASHBOARD}/campaigns/list`,
            cards: `${ROOTS.DASHBOARD}/campaigns/cards`,
            profile: `${ROOTS.DASHBOARD}/campaigns/profile`,
            account: `${ROOTS.DASHBOARD}/campaigns/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/campaigns/${id}/edit`,
        },
        contactForm: {
            list: `${ROOTS.DASHBOARD}/contactForm/list`,
            new: `${ROOTS.DASHBOARD}/contactForm/new`,
            edit: (id) => `${ROOTS.DASHBOARD}/contactForm/${id}/edit`,
        },

        task: {
            root: `${ROOTS.DASHBOARD}/task`,
            list: `${ROOTS.DASHBOARD}/task/list`,
            new: `${ROOTS.DASHBOARD}/task/new`,
        },
        project: {
            root: `${ROOTS.DASHBOARD}/project`,
            list: `${ROOTS.DASHBOARD}/project/list`,
            new: `${ROOTS.DASHBOARD}/project/new`,
            thematic: `${ROOTS.DASHBOARD}/project/thematic`,
            profile: (projectId) =>
                `${ROOTS.DASHBOARD}/project/profile/${projectId}`,
        },
        product: {
            root: `${ROOTS.DASHBOARD}/product`,
            new: `${ROOTS.DASHBOARD}/product/new`,
            details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
            demo: {
                details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
                edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
            },
        },
        invoice: {
            root: `${ROOTS.DASHBOARD}/invoice`,
            new: `${ROOTS.DASHBOARD}/invoice/new`,
            details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
        },
        quotation: {
            root: `${ROOTS.DASHBOARD}/quotation`,
            new: `${ROOTS.DASHBOARD}/quotation/new`,
            details: (id) => `${ROOTS.DASHBOARD}/quotation/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/quotation/${id}/edit`,
        },
        post: {
            root: `${ROOTS.DASHBOARD}/post`,
            new: `${ROOTS.DASHBOARD}/post/new`,
            details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
            edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
            demo: {
                details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
                edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
            },
        },
        order: {
            root: `${ROOTS.DASHBOARD}/order`,
            details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
            demo: {
                details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
            },
        },
        booking: {
            root: `${ROOTS.DASHBOARD}/booking`,
            details: (id) => `${ROOTS.DASHBOARD}/booking/${id}`,
            demo: {
                details: `${ROOTS.DASHBOARD}/booking/${MOCK_ID}`,
            },
        },
        job: {
            root: `${ROOTS.DASHBOARD}/job`,
            new: `${ROOTS.DASHBOARD}/job/new`,
            details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
            demo: {
                details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
                edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
            },
        },
        ourproducts: {
            root: `${ROOTS.DASHBOARD}/ourproducts`,
            new: `${ROOTS.DASHBOARD}/ourproducts/new`,
            details: (id) => `${ROOTS.DASHBOARD}/ourproducts/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/ourproducts/${id}/edit`,
        },
        tour: {
            root: `${ROOTS.DASHBOARD}/tour`,
            new: `${ROOTS.DASHBOARD}/tour/new`,
            details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
            demo: {
                details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
                edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
            },
        },
        promotion: {
            root: `${ROOTS.DASHBOARD}/promotion`,
            new: `${ROOTS.DASHBOARD}/promotion/new`,
            details: (id) => `${ROOTS.DASHBOARD}/promotion/${id}`,
            edit: (id) => `${ROOTS.DASHBOARD}/promotion/${id}/edit`,
        },
        activity: {
            root: `${ROOTS.DASHBOARD}/activity`,
            list: `${ROOTS.DASHBOARD}/activity/list`,
            new: `${ROOTS.DASHBOARD}/activity/new`,
            details: (productCode) =>
                `${ROOTS.DASHBOARD}/activity/${productCode}`,
            edit: (productCode) =>
                `${ROOTS.DASHBOARD}/activity/${productCode}/edit`,
            checkout: (code) => `${ROOTS.DASHBOARD}/activity/checkout/${code}`,
        },
        hotel: {
            list: `${ROOTS.DASHBOARD}/hotel/list`,
            details: (gdsprovider, code) =>
                `${ROOTS.DASHBOARD}/hotel/hotelDetails/${gdsprovider}/${code}`,
            edit: (code) => `${ROOTS.DASHBOARD}/hotel/${code}/code`,
            checkout: (code, rateKey) =>
                `${ROOTS.DASHBOARD}/hotel/checkout/${code}/${rateKey}`,
        },
        expense: {
            root: `${ROOTS.DASHBOARD}/expense`,
            new: `${ROOTS.DASHBOARD}/expense/new`,
            list: `${ROOTS.DASHBOARD}/expense/list`,
            cards: `${ROOTS.DASHBOARD}/expense/cards`,
            profile: (expenseId) =>
                `${ROOTS.DASHBOARD}/expense/profile/${expenseId}`,
            account: `${ROOTS.DASHBOARD}/expense/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/expense/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/expense/${MOCK_ID}/edit`,
            },
        },
        income: {
            root: `${ROOTS.DASHBOARD}/income`,
            new: `${ROOTS.DASHBOARD}/income/new`,
            list: `${ROOTS.DASHBOARD}/income/list`,
            cards: `${ROOTS.DASHBOARD}/income/cards`,
            profile: (incomeId) =>
                `${ROOTS.DASHBOARD}/income/profile/${incomeId}`,
            account: `${ROOTS.DASHBOARD}/income/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/income/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/income/${MOCK_ID}/edit`,
            },
        },
        ledger: {
            root: `${ROOTS.DASHBOARD}/ledger`,
            new: `${ROOTS.DASHBOARD}/ledger/new`,
            list: `${ROOTS.DASHBOARD}/ledger/list`,
            cards: `${ROOTS.DASHBOARD}/ledger/cards`,
            profile: (ledgerId) =>
                `${ROOTS.DASHBOARD}/ledger/profile/${ledgerId}`,
            account: `${ROOTS.DASHBOARD}/ledger/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/ledger/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/ledger/${MOCK_ID}/edit`,
            },
        },
        chAccount: {
            root: `${ROOTS.DASHBOARD}/account`,
            new: `${ROOTS.DASHBOARD}/account/new`,
            list: `${ROOTS.DASHBOARD}/account/list`,
            cards: `${ROOTS.DASHBOARD}/account/cards`,
            profile: (ledgerId) =>
                `${ROOTS.DASHBOARD}/account/profile/${ledgerId}`,
            account: `${ROOTS.DASHBOARD}/account/account`,
            edit: (id) => `${ROOTS.DASHBOARD}/account/${id}/edit`,
            demo: {
                edit: `${ROOTS.DASHBOARD}/account/${MOCK_ID}/edit`,
            },
        },
        flight: {
            home: `${ROOTS.DASHBOARD}/flight`,
            checkout: (offerId) =>
                `${ROOTS.DASHBOARD}/flight/checkout/${offerId}`,
        },
        transfer: {
            home: `${ROOTS.DASHBOARD}/transfer`,
            checkout: (offerId) =>
                `${ROOTS.DASHBOARD}/transfer/checkout/${offerId}`,
        },
        carRental: {
            home: `${ROOTS.DASHBOARD}/carRental`,
            checkout: (offerId) =>
                `${ROOTS.DASHBOARD}/carRental/checkout/${offerId}`,
        },
        cruises: {
            home: `${ROOTS.DASHBOARD}/cruises`,
            proposal: (id, agent) =>
                `/client-view-cruises-proposal/${id}?type=${
                    agent ? "agent" : "customer"
                }`,
            details: (masterCruiseId) =>
                `${ROOTS.DASHBOARD}/cruises/details/${masterCruiseId}`,
            checkout: (offerId) =>
                `${ROOTS.DASHBOARD}/cruises/checkout/${offerId}`,
        },
    },
};
