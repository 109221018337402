import { _mock } from "./_mock";

// ----------------------------------------------------------------------

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.fullName(index),
    role: _mock.role(index),
    avatarUrl: _mock.image.portrait(index),
}));

// ----------------------------------------------------------------------

export const _faqs = [
    {
        id: "1",
        value: "panel1",
        heading: "How do I register my travel agency on Travelandz?",
        detail: "To register your travel agency, click on the 'Sign Up' button on our homepage. Fill in your agency details, verify your email, and start using Travelandz's AI-powered tools for seamless travel management.",
    },
    {
        id: "2",
        value: "panel2",
        heading: "What are the steps to create a booking on Travelandz?",
        detail: "Creating a booking is simple. Log in to your account, choose your service (e.g., flights, hotels, tours), use the AI planner to customize your client’s itinerary, and confirm the booking with payment.",
    },
    {
        id: "3",
        value: "panel3",
        heading: "Can I manage multiple client bookings at the same time?",
        detail: "Yes, Travelandz offers a centralized dashboard that allows you to handle multiple client bookings simultaneously, track their statuses, and communicate updates effortlessly.",
    },
    {
        id: "4",
        value: "panel4",
        heading:
            "Is it possible to block a price or hold a booking temporarily?",
        detail: "Absolutely! Travelandz allows you to block prices or hold bookings temporarily for your clients while they decide. You’ll have a specific timeframe to confirm or release the hold.",
    },
    {
        id: "5",
        value: "panel5",
        heading:
            "How can I use AI tools to create customized travel proposals?",
        detail: "Travelandz's AI-powered tools let you design tailored travel proposals in minutes. Input client preferences, and our AI will generate detailed itineraries, pricing, and travel options.",
    },
];

export const _faqsAiAgents = [
    {
        id: "1",
        value: "panel1",
        heading: "What is AI, and how can it benefit travel agencies?",
        detail: "Artificial Intelligence (AI) is transforming the travel industry by automating repetitive tasks and enhancing decision-making. Travelandz.com offers powerful AI-driven tools to help travel agencies streamline operations, personalize customer experiences, and stay competitive. From automating booking management to providing predictive insights, Travelandz empowers travel agents to focus on what matters most: providing exceptional service.",
        link: "https://travelandz.com/ai-travel-agents/what-is-ai-and-how-can-it-benefit-travel-agencies",
    },
    {
        id: "2",
        value: "panel2",
        heading: "How is AI transforming the travel industry?",
        detail: "AI is reshaping the travel industry by automating tasks like booking management, customer service, and data analysis. Travelandz.com leverages AI to provide seamless integrations that improve operational efficiency and customer satisfaction. With AI-powered tools, Travelandz helps travel agencies enhance their service offerings, making the booking process faster, easier, and more personalized.",
        link: "https://travelandz.com/ai-travel-agents/how-is-ai-transforming-the-travel-industry",
    },
    {
        id: "3",
        value: "panel3",
        heading: "What are the best AI tools for travel agencies in 2025?",
        detail: "In 2025, AI tools like itinerary planners, dynamic pricing engines, and customer support bots will be critical to travel agencies' success. Travelandz.com offers a comprehensive suite of AI tools designed to automate workflows, increase client satisfaction, and boost operational efficiency. By integrating Travelandz’s AI solutions, travel agencies can stay ahead of the curve and provide cutting-edge services to their clients.",
        link: "https://travelandz.com/ai-travel-agents/best-ai-tools-for-travel-agencies-in-2025",
    },
    {
        id: "4",
        value: "panel4",
        heading:
            "How can small travel agencies adopt AI without breaking the bank?",
        detail: "Small travel agencies can use AI to streamline their operations without large upfront investments. Travelandz.com offers affordable, scalable AI tools that automate routine tasks like booking management, client communication, and data entry. With Travelandz, small agencies can adopt AI solutions that fit their budget while delivering high-value services and personalized experiences for their clients.",
        link: "https://travelandz.com/ai-travel-agents/how-can-small-agencies-adopt-ai-affordably",
    },
    {
        id: "5",
        value: "panel5",
        heading:
            "How does AI help travel agencies save time on booking management?",
        detail: "AI automates time-consuming tasks such as itinerary creation, booking confirmations, and customer data management. Travelandz.com’s AI-powered tools allow travel agents to quickly design personalized travel proposals, automate booking confirmations, and offer dynamic pricing based on market conditions. By using Travelandz, travel agencies can save hours of administrative work, allowing them to focus on client relationships and business growth.",
        link: "https://travelandz.com/ai-travel-agents/how-does-ai-save-time-on-bookings",
    },
    {
        id: "6",
        value: "panel6",
        heading: "How does AI streamline customer service in travel agencies?",
        detail: "AI-powered chatbots and virtual assistants can provide 24/7 customer support, answering queries and solving problems in real-time. Travelandz.com offers AI-driven customer service solutions that help travel agencies offer instant support to their clients, reduce wait times, and improve customer satisfaction. With Travelandz’s AI tools, travel agencies can enhance their customer service and manage requests efficiently.",
        link: "https://travelandz.com/ai-travel-agents/how-does-ai-improve-customer-service",
    },
    {
        id: "7",
        value: "panel7",
        heading: "What operational challenges can AI solve for travel agents?",
        detail: "AI can solve a wide range of operational challenges faced by travel agents, from automating booking workflows to generating personalized travel itineraries. Travelandz.com’s AI tools help agencies overcome these hurdles by streamlining repetitive tasks, improving efficiency, and enhancing decision-making. Whether it's processing bookings or providing market insights, Travelandz equips travel agencies with the tools they need to succeed.",
        link: "https://travelandz.com/ai-travel-agents/what-operational-challenges-can-ai-solve",
    },
    {
        id: "8",
        value: "panel8",
        heading: "How does AI improve the booking experience for travelers?",
        detail: "AI enhances the booking experience by analyzing client preferences and offering personalized recommendations for flights, hotels, and activities. Travelandz.com’s AI tools allow travel agencies to deliver highly tailored experiences, ensuring clients receive the best options based on their unique needs. With Travelandz, travel agencies can create seamless booking experiences that delight travelers and drive repeat business.",
        link: "https://travelandz.com/ai-travel-agents/how-does-ai-improve-the-booking-experience",
    },
    {
        id: "9",
        value: "panel9",
        heading:
            "What is dynamic pricing, and how does AI make it possible in travel agencies?",
        detail: "Dynamic pricing is the practice of adjusting prices based on demand and market conditions. Travelandz.com’s AI-powered dynamic pricing engine helps travel agencies optimize their pricing strategies by analyzing real-time data, forecasting demand, and offering personalized prices to clients. With AI, Travelandz enables travel agencies to stay competitive and maximize revenue by adjusting prices in real-time.",
        link: "https://travelandz.com/ai-travel-agents/what-is-dynamic-pricing-and-how-ai-makes-it-possible",
    },
    {
        id: "10",
        value: "panel10",
        heading: "Will AI replace human travel agents?",
        detail: "AI is not designed to replace human travel agents but to assist them in delivering better services. Travelandz.com’s AI tools automate repetitive tasks, like booking management and itinerary generation, allowing agents to focus on more complex tasks that require human expertise. AI enhances the travel agent’s role by providing valuable insights and tools that make their job more efficient and their clients’ experience better.",
        link: "https://travelandz.com/ai-travel-agents/will-ai-replace-human-travel-agents",
    },
];

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(24)].map((_, index) => ({
    id: _mock.id(index),
    primary: index === 0,
    name: _mock.fullName(index),
    email: _mock.email(index + 1),
    fullAddress: _mock.fullAddress(index),
    phoneNumber: _mock.phoneNumber(index),
    company: _mock.companyName(index + 1),
    addressType: index === 0 ? "Home" : "Office",
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => {
    const status =
        (index % 2 && "online") ||
        (index % 3 && "offline") ||
        (index % 4 && "alway") ||
        "busy";

    return {
        id: _mock.id(index),
        status,
        role: _mock.role(index),
        email: _mock.email(index),
        name: _mock.fullName(index),
        phoneNumber: _mock.phoneNumber(index),
        lastActivity: _mock.time(index),
        avatarUrl: _mock.image.avatar(index),
        address: _mock.fullAddress(index),
    };
});

// ----------------------------------------------------------------------

export const _notifications = [...Array(9)].map((_, index) => ({
    id: _mock.id(index),
    avatarUrl: [
        _mock.image.avatar(1),
        _mock.image.avatar(2),
        _mock.image.avatar(3),
        _mock.image.avatar(4),
        _mock.image.avatar(5),
        null,
        null,
        null,
        null,
        null,
    ][index],
    type: [
        "friend",
        "project",
        "file",
        "tags",
        "payment",
        "order",
        "chat",
        "mail",
        "delivery",
    ][index],
    category: [
        "Communication",
        "Project UI",
        "File Manager",
        "File Manager",
        "File Manager",
        "Order",
        "Order",
        "Communication",
        "Communication",
    ][index],
    isUnRead: _mock.boolean(index),
    createdAt: _mock.time(index),
    title:
        (index === 0 &&
            `<p><strong>Deja Brady</strong> sent you a friend request</p>`) ||
        (index === 1 &&
            `<p><strong>Jayvon Hull</strong> mentioned you in <strong><a href='#'>Minimal UI</a></strong></p>`) ||
        (index === 2 &&
            `<p><strong>Lainey Davidson</strong> added file to <strong><a href='#'>File Manager</a></strong></p>`) ||
        (index === 3 &&
            `<p><strong>Angelique Morse</strong> added new tags to <strong><a href='#'>File Manager<a/></strong></p>`) ||
        (index === 4 &&
            `<p><strong>Giana Brandt</strong> request a payment of <strong>$200</strong></p>`) ||
        (index === 5 && `<p>Your order is placed waiting for shipping</p>`) ||
        (index === 6 &&
            `<p>Delivery processing your order is being shipped</p>`) ||
        (index === 7 && `<p>You have new message 5 unread messages</p>`) ||
        (index === 8 && `<p>You have new mail`) ||
        "",
}));

// ----------------------------------------------------------------------

export const _mapContact = [
    {
        latlng: [33, 65],
        address: _mock.fullAddress(1),
        phoneNumber: _mock.phoneNumber(1),
    },
    {
        latlng: [-12.5, 18.5],
        address: _mock.fullAddress(2),
        phoneNumber: _mock.phoneNumber(2),
    },
];

// ----------------------------------------------------------------------

export const _socials = [
    {
        value: "facebook",
        name: "FaceBook",
        icon: "eva:facebook-fill",
        color: "#1877F2",
        path: "https://facebook.com/profile.php?id=100091462146711",
    },
    {
        value: "instagram",
        name: "Instagram",
        icon: "ant-design:instagram-filled",
        color: "#E02D69",
        path: "https://instagram.com/travelandzai/",
    },
    {
        value: "linkedin",
        name: "Linkedin",
        icon: "eva:linkedin-fill",
        color: "#007EBB",
        path: "https://linkedin.com/company/travelandz",
    },
    {
        value: "twitter",
        name: "Twitter",
        icon: "eva:twitter-fill",
        color: "#00AAEC",
        path: "https://twitter.com/travelandz",
    },
];

// ----------------------------------------------------------------------

export const _homePlans = [...Array(3)].map((_, index) => ({
    license: ["Basic", "Professional", "Enterprise"][index],
    commons: [
        "CRM for Leads and Customers",
        "Basic Sales Funnel Management",
        "Trip Generation via Contact Form",
        "Limited Bookings: Hotels, Flights, and Activities",
        "Itinerary Generation (Basic)",
        "Content Management",
    ],
    options: [
        "Book Hotels, Activities, Transfers",
        "FootPrint Calculation & Offset",
        "Automatic Requests for Quotaions",
        "Automatic Lead Management",
        "Premium Support",
    ],
    icons: [
        "/assets/icons/platforms/ic_figma.svg",
        "/assets/icons/platforms/ic_js.svg",
        "/assets/icons/platforms/ic_ts.svg",
    ],
}));

// ----------------------------------------------------------------------

export const _pricingPlans = [
    {
        subscription: "Trial Period",
        level: 0,
        price: 0,
        caption: "3 Months Free, Cancel Anytime",
        caption2: "Start Right Now Saving Costs",
        lists: [
            "Access to flight, hotel, and car rental booking platform",
            "Basic travel planning tools (itineraries, reminders)",
            "Support via email or chat during business hours",
            "Travel expense reports for the company",
            "Virtual credit card for payments",
            "Monthly report with booking details and general expenses",
            "Manual approval process for trips",
            "Limited to up to 10 employees",
            "Manual payments with stored credit/debit cards",
            "Monthly or per-booking billing",
            "Access to standard rates for flights and hotels",
            "Basic team travel management (limited features)",
            "Cancel Anytime",
        ],
        labelAction: "Start For Free",
    },
    {
        subscription: "Basic Membership",
        price: 9.99,
        caption: "Best Option for Small Teams",
        caption2: "**No monthly fee if you make 2 bookings in a month",
        lists: [
            "Access to flight, hotel, and car rental booking platform",
            "Basic travel planning tools (itineraries, reminders)",
            "Support via email or chat during business hours",
            "Travel expense reports for the company",
            "Virtual credit card for payments",
            "Monthly report with booking details and general expenses",
            "Manual approval process for trips",
            "Limited to up to 10 employees",
            "Manual payments with stored credit/debit cards",
            "Monthly or per-booking billing",
            "Access to standard rates for flights and hotels",
            "Basic team travel management (limited features)",
            "Cancel Anytime",
        ],
        labelAction: "Choose Basic",
    },

    {
        subscription: "Premium Membership",
        price: 39.99,
        caption: "Unlock exclusive benefits",
        caption2: "**No monthly fee if you make 2 bookings in a month",
        lists: [
            "Priority bookings, automatic upgrades (when available), and exclusive rates",
            "Advanced travel planning tools with calendar integrations and personalized itineraries",
            "24/7 premium customer support with dedicated agents and personalized phone service",
            "Access to an account manager for real-time assistance",
            "Custom travel expense reports with advanced filters",
            "Real-time reports and interactive dashboards for better control",
            "Automated travel approval processes based on predefined company policies",
            "Real-time budget usage alerts",
            "Unlimited employees and administrators on the platform",
            "Integration with financial management systems (ERP) for automated billing",
            "Installment payments or corporate credit line options",
            "Access to discounted corporate rates with better conditions (free cancellation, flexible rates)",
            "Bookings with premium airlines and hotels, including added perks (lounge access, upgrades)",
            "Advanced team travel management tools, including delegation and automated approvals",
            "Integration with project management tools and automated team travel reports",
            "Centralized management of loyalty points for airlines, hotels, and car rentals",
            "Accumulate special benefits such as extra miles or free nights",
            "Advanced security features, such as real-time traveler tracking for emergencies",
            "Compliance reports (e.g., GDPR)",
            "Cancel Anytime",
        ],
        labelAction: "Choose Premium",
    },
];

// ----------------------------------------------------------------------

export const _testimonials = [
    {
        name: _mock.fullName(1),
        postedDate: _mock.time(1),
        ratingNumber: _mock.number.rating(1),
        avatarUrl: _mock.image.avatar(1),
        content: `Travelandz software faciltates the daily tasks of my travel agency. It helps me save time and be more efficient.`,
    },
    {
        name: _mock.fullName(2),
        postedDate: _mock.time(2),
        ratingNumber: _mock.number.rating(2),
        avatarUrl: _mock.image.avatar(2),
        content: `I can't imagine running our travel agency without Travelandz. The software's AI-driven insights have boosted our sales and helped us stay competitive in the market. It's user-friendly and has improved our overall efficiency.`,
    },
    {
        name: _mock.fullName(3),
        postedDate: _mock.time(3),
        ratingNumber: _mock.number.rating(3),
        avatarUrl: _mock.image.avatar(3),
        content: `The Travelandz team has been incredibly helpful and professional, ensuring that we have all the support we need. Highly recommended!`,
    },
    {
        name: _mock.fullName(4),
        postedDate: _mock.time(4),
        ratingNumber: _mock.number.rating(4),
        avatarUrl: _mock.image.avatar(4),
        content: `We've been using Travelandz for a while now, and it's been a fantastic addition to our travel agency. The dashboard is user-friendly, and makes our daily tasks a breeze. We recommend Travelandz to every travel agency!`,
    },
    {
        name: _mock.fullName(5),
        postedDate: _mock.time(5),
        ratingNumber: _mock.number.rating(5),
        avatarUrl: _mock.image.avatar(5),
        content: `Travelandz is the all-in-one platform our travel agency was looking for. It handles everything from trip planning to client management and beyond.`,
    },
    {
        name: _mock.fullName(6),
        postedDate: _mock.time(6),
        ratingNumber: _mock.number.rating(6),
        avatarUrl: _mock.image.avatar(6),
        content: `The software has truly transformed our travel agency's accountability. With its intuitive features, it has made generating invoices a seconds. Thanks!`,
    },
];
