import PropTypes from "prop-types";
// @mui
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
// routes
import { RouterLink } from "src/routes/components";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
    const { t } = useLocales();
    return (
        <>
            <Link
                component={RouterLink}
                href="https://travelandz.business/business-travel/auth/login?returnTo=%2Fdashboard%2Fhome"
                variant="outlined"
                sx={{
                    padding: "14px 18px", // Adjust padding for larger size
                    fontSize: { xs: "1rem", md: "1.25rem" }, // Responsive font size for XS and MD
                    borderRadius: "8px", // Optional: rounded corners
                    color: "black",
                    mr: 1, // Retain existing margin-right
                    ...sx, // Spread operator for additional styles
                }}
            >
                {t("Login/Sign Up")}
            </Link>
        </>
    );
}

LoginButton.propTypes = {
    sx: PropTypes.object,
};
